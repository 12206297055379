.destacada-container {
	color: var(--dark-black);
	letter-spacing: 0;
	text-align: left;
	width: 100%;
}

.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.destacada-container-text {
	margin-bottom: 0rem;
	width: 100%;
}

.destacada-title {
	font-size: 2rem;
	margin-top: 0.5rem;
	margin-bottom: 0.7rem;
	letter-spacing: -1px;
}

.destacada-summary {
	font-size: 1rem;
	line-height: 1.375;
	font-weight: 400;
	color: var(--gray-text);
}

.background{
	background: #fff000;
	padding: 20px 10px;
}

.destacada-autor {
	margin-top: 1rem;
	color: var(--medium-black);
    font-weight: 700;
    font-size: .9rem;
}

.display-none {
	display: none;
}

@media (max-width: 768px) {
	.img-container{
		min-height: 0px;
	}
	.destacada-container-text {
		margin-bottom: 0rem;
	}
	.destacada-title {
		font-size: 1.5rem;
		margin-top: 0.6rem;
		margin-bottom: 0.6rem;
	}
	.destacada-summary {
		margin: 0rem 0;
	}
	.destacada-autor {
		margin-top: 0.6rem;
	}
}